import React, { useState } from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import * as contactUsStyles from "./contact-us.module.scss"

const ContactUs = ({ pageContext, location }) => {
  const [formState, setFormState] = useState({
    inquiryRelatedTo: [],
    name: "",
    email: "",
    subject: "",
    phone: "",
    company: "",
    message: "",
  })
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  })

  const handleChange = event => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
          ? [...formState[event.target.name], event.target.value]
          : formState[event.target.name].filter(
              item => item !== event.target.value
            )
        : event.target.value

    setFormState({
      ...formState,
      [event.target.name]: value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    // Define validation functions
    const isRequired = value => (value ? "" : "This field is required.")
    const isValidEmail = value =>
      /\S+@\S+\.\S+/.test(value) ? "" : "Please provide a valid email address."

    const fieldsToValidate = [
      { field: "name", validate: isRequired },
      { field: "email", validate: [isRequired, isValidEmail] },
      { field: "subject", validate: isRequired },
      { field: "phone", validate: isRequired },
      { field: "message", validate: isRequired },
    ]

    let newErrors = {}
    fieldsToValidate.forEach(({ field, validate }) => {
      const value = formState[field]
      const validators = Array.isArray(validate) ? validate : [validate]
      validators.forEach(validator => {
        const error = validator(value)
        if (error) {
          newErrors[field] = error
        }
      })
    })

    if (Object.keys(newErrors).length > 0) {
      // Update error state with new errors
      setErrors(newErrors)

      // Find the first field with an error and focus on it
      const firstErrorField = document.querySelector(
        `#${Object.keys(newErrors)[0]}`
      )
      if (firstErrorField) {
        firstErrorField.focus()
      }
    } else {
      // If no errors, perform form submission
      const form = event.target
      const formData = new FormData(form)
      fetch("/", {
        method: "POST",
        body: formData,
      })
        .then(navigate("/form-confirmation/"))
        .catch(error => alert(error))
    }
  }

  const pageTitle = "Contact Us"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h2>Contact Us</h2>
        <h3>Corporate Headquarters</h3>
        <ul>
          <li>39 E. Canal St.</li>
          <li>Nelsonville, OH 45764</li>
          <li>
            <a href="tel:17407539100">1-740-753-9100</a>
          </li>
          <li>
            <a href="tel:18887195108">1-888-719-5108</a>
          </li>
        </ul>
        <br />
        <ul>
          <li>
            Shop Our Brands or Find a Dealer:{" "}
            <Link to="/brands" title="Shop Our Brands">
              <strong>Here</strong>
            </Link>
          </li>
          <li>
            PR Inquires:{" "}
            <Link to="/public-relations" title="Public Relations">
              <strong>Here</strong>
            </Link>
          </li>
          <li>
            Sponsorship Inquires:{" "}
            <Link
              to="/public-relations/sponsorship-requests"
              title="Sponsorship Requests"
            >
              <strong>Here</strong>
            </Link>
          </li>
          <li>
            Investor Relations:{" "}
            <Link
              to="https://investors.rockybrands.com"
              title="Investor Relations"
            >
              <strong>Here</strong>
            </Link>
          </li>
        </ul>

        <h2 className="h5" style={{ marginTop: `4rem` }}>
          For General Information:
        </h2>
        {/* contact form */}
        <form
          name="contact"
          data-netlify="true"
          method="POST"
          data-netlify-honeypot="bot-field"
          style={{ maxWidth: `50rem` }}
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <small>
            <span
              className="text-danger"
              style={{ fontSize: `1.1rem`, fontWeight: `bold` }}
            >
              *
            </span>{" "}
            indicates required field
          </small>
          {/* checkboxes Inquiry Related To: */}
          <fieldset className="form-group" style={{ marginBottom: `2rem` }}>
            <legend htmlFor="inquiryRelatedTo">
              <strong>Inquiry Related To:</strong>
            </legend>

            <div>
              <label className="checkbox-alt">
                <input
                  type="checkbox"
                  id="inquiryRelatedTo-investor-information"
                  name="inquiryRelatedTo"
                  value="Investor Information"
                  onChange={handleChange}
                />
                <span>Investor Information</span>
              </label>
            </div>
            <div>
              <label className="checkbox-alt">
                <input
                  type="checkbox"
                  id="inquiryRelatedTo-become-a-rocky-brands-retailer"
                  name="inquiryRelatedTo"
                  value="Become a Rocky Brands Retailer"
                  onChange={handleChange}
                />
                <span>Become a Rocky Brands Retailer</span>
              </label>
            </div>
            <div>
              <label className="checkbox-alt">
                <input
                  type="checkbox"
                  id="inquiryRelatedTo-career-opportunity"
                  name="inquiryRelatedTo"
                  value="Career Opportunity"
                  onChange={handleChange}
                />
                <span>Career Opportunity</span>
              </label>
            </div>
            <div>
              <label className="checkbox-alt">
                <input
                  type="checkbox"
                  id="inquiryRelatedTo-consumer-online-order-inquiry"
                  name="inquiryRelatedTo"
                  value="Consumer Online Order Inquiry"
                  onChange={handleChange}
                />
                <span>Consumer Online Order Inquiry</span>
              </label>
            </div>
            <div>
              <label className="checkbox-alt">
                <input
                  type="checkbox"
                  id="inquiryRelatedTo-order-return"
                  name="inquiryRelatedTo"
                  value="Order Return"
                  onChange={handleChange}
                />
                <span>Order Return</span>
              </label>
            </div>
            <div>
              <label className="checkbox-alt">
                <input
                  type="checkbox"
                  id="inquiryRelatedTo-general-product-inquiry"
                  name="inquiryRelatedTo"
                  value="General Product Inquiry"
                />
                <span>General Product Inquiry</span>
              </label>
            </div>
            <div>
              <label className="checkbox-alt">
                <input
                  type="checkbox"
                  id="inquiryRelatedTo-advertising-sponsorship-request"
                  name="inquiryRelatedTo"
                  value="Advertising / Sponsorship Request"
                />
                <span>Advertising/Sponsorship Request</span>
              </label>
            </div>
            <div>
              <label className="checkbox-alt">
                <input
                  type="checkbox"
                  id="inquiryRelatedTo-other"
                  name="inquiryRelatedTo"
                  value="Other"
                />
                <span>Other</span>
              </label>
            </div>
            <small>
              Your selection above will ensure your inquiry is routed to the
              appropriate department.
            </small>
          </fieldset>
          {/* name */}
          <div className="form-group">
            <label className="required" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              autoComplete="name"
              placeholder="Your Name"
              value={formState.name}
              onChange={handleChange}
              aria-describedby={errors.name ? "nameError" : null}
              aria-required
            />
            {errors.name && (
              <small id="nameError" className="text-danger">
                {errors.name}
              </small>
            )}
          </div>
          {/* email */}
          <div className="form-group">
            <label className="required" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Your Email"
              autoComplete="email"
              value={formState.email}
              onChange={handleChange}
              aria-describedby={errors.email ? "emailError" : null}
              aria-required
            />
            {errors.email ? (
              <small id="email-error" className="text-danger">
                {errors.email} (ie. info@example.com)
              </small>
            ) : (
              <small>ie. info@example.com</small>
            )}
          </div>
          <div className="form-group">
            <label className="required" htmlFor="subject">
              Subject
            </label>
            <input
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              placeholder="Subject"
              value={formState.subject}
              onChange={handleChange}
              aria-describedby={errors.subject ? "subjectError" : null}
              aria-required
            />
            {errors.subject && (
              <small id="subjectError" className="text-danger">
                {errors.subject}
              </small>
            )}
          </div>
          <div className="form-group">
            <label className="required" htmlFor="phone">
              Phone
            </label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              name="phone"
              autoComplete="tel"
              placeholder="Your Phone"
              value={formState.phone}
              onChange={handleChange}
              aria-describedby={errors.phone ? "phoneError" : null}
              aria-required
            />
            {errors.phone && (
              <small id="phoneError" className="text-danger">
                {errors.phone}
              </small>
            )}
          </div>
          {/* Company/Store Name */}
          <div className="form-group">
            <label htmlFor="company">Company/Store Name</label>
            <input
              type="text"
              className="form-control"
              id="company"
              name="company"
              placeholder="Company/Store Name"
              autoComplete="organization"
              value={formState.company}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="required" htmlFor="message">
              Message
            </label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="3"
              placeholder="Your Message"
              value={formState.message}
              onChange={handleChange}
              aria-describedby={errors.message ? "messageError" : null}
              aria-required
            />
            {errors.message && (
              <small id="messageError" className="text-danger">
                {errors.message}
              </small>
            )}
          </div>
          <div>
            <input className="CTAblack" type="submit" value="Submit" />
          </div>
        </form>
      </div>

      {/* for search indexing only */}
      <p
        className="sr-only"
        data-typesense-field="description"
        aria-hidden="true"
      >
        Get in touch with Rocky Brands. Reach us for inquiries related to
        Investor Information, becoming a retailer, career opportunities, order
        queries, product information, sponsorships and more. Our team is here to
        provide you with assistance and guidance.{" "}
      </p>
    </Layout>
  )
}

export default ContactUs

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Contact Us</title>
    <meta
      name="description"
      content="
      Get in touch with Rocky Brands. Reach us for inquiries related to Investor Information, becoming a retailer, career opportunities, order queries, product information, sponsorships and more. Our team is here to provide you with assistance and guidance. 
      "
    />
  </>
)
